
import Modal from "@/components/Modal.vue";
import { Select, Option } from "element-ui";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import {
  Component,
  ProvideReactive,
  Prop,
  Vue,
  Watch,
  Emit,
} from "vue-property-decorator";
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from "vuex";
import { eventHandler, translations } from "@/mixins";
import { Settings } from "@/services/SOLO";
import { AttrCustomField } from "@/models";

interface itemCustomField {
  id: string;
  value: string;
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mixins: [eventHandler, translations],
})
export default class CustomFieldsModal extends Vue {
  customField: itemCustomField = {
    id: "",
    value: "",
  };

  @Prop() open!: Boolean;
  @Prop() globalCustomFields!: Array<AttrCustomField>;
  @Prop() itemCustomFields!: Array<AttrCustomField>;
  @Prop() categoryId!: string;
  @Prop() modifyField!: any;

  waiting!: Function;
  restore!: Function;
  translate!: Function;
  $notify: any;

  @Watch("open", {immediate: true, deep: true})
  openChanged(newVal: any) {
    console.log('open newVal: ', newVal);
    if (!newVal) {
      this.customField = {
        id: '',
        value: ''
      };
      this.$emit('modal:clear')
    }

  }

  @Watch("modifyField", {immediate: true, deep: true})
  modifyFieldChanged(newVal: any) {
    console.log('newVal: ', newVal);
    if (newVal) {
      this.customField.id = newVal['custom-field-id'];
      this.customField.value = newVal.value;
    }
  }

  get filteredGlobalCustomFields(): Array<AttrCustomField> {
    return this.globalCustomFields.filter((field) => {
      return !this.itemCustomFields.includes(field.id);
    });
  }

  get isOpen(): Boolean {
    return this.open;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("modal:update", newVal);
  }

  attachField(e: HTMLFormElement, reset: any) {
    let payload = {
      entity: "category",
      "entity-id": this.categoryId,
      value: this.customField.value,
    };
    const defaultText = e.submitter.innerHTML;
    this.waiting(e, '<i class="fas fa-spinner fa-spin"></i> Saving');
    Settings.attachField(this.customField.id, payload)
      .then((response: any) => {
        let data = response.data.data;
        this.restore(e, defaultText);
        this.$emit("field:saved", {
          "custom-field": data.attributes.label,
          "custom-field-id": data.id,
          value: payload.value,
          type: this.modifyField ? 'update' : 'create',
        });
        reset();
        this.customField = {
          id: "",
          value: "",
        };
        this.$notify({
          title: "DATA SAVED",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: `Custom field has been ${this.modifyField ? 'updated' : 'added'}!`,
          type: "success",
          icon: "fas fa-check",
        });
      })
      .catch((err: any) => {
        console.error(err);
        this.restore(e, defaultText);
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }
}
